import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import AMillionWaysHero from '../../components/a-million-ways-hero'
import AMillionWaysCtaBlock from '../../components/a-million-ways-cta-block'
import AMillionWaysStyles from '../../css/pages/a-million-ways-styles'

const query = graphql`
  query {
    strapiAMillionWays {
      sections
    }
  }
`

const AMillionWays = () => {
  function renderCustomLink(href, children) {
    const isExternalLink = href.includes('http')
    return (
      /* eslint-disable react/jsx-no-target-blank */
      <a
        href={href}
        className={`a-million-ways__button ${!isExternalLink ? 'nts-app' : ''}`}
        target={isExternalLink ? '_blank' : '_self'}
        rel={isExternalLink ? 'noopener noreferrer': ''}>
        {children}
      </a>
      /* eslint-enable react/jsx-no-target-blank */
    )
  }

  function renderHero(section) {
    return (
      <AMillionWaysHero
        section={section}
        key={section.strapi_component} />
    )
  }

  function renderAbout(section) {
    return (
      <section
        className="a-million-ways__section a-million-ways__about a-million-ways__about--project"
        key={section.strapi_component}>
        <div className="a-million-ways__section-wrapper">
          <div className="a-million-ways__about-paragraph-container">
            <div
              className="a-million-ways__paragraph"
              dangerouslySetInnerHTML={{ __html: section.body.replace(/\n/g, '<br />') }} />
          </div>
        </div>
      </section>
    )
  }

  function renderTalents(section) {
    return (
      <section
        className="a-million-ways__section a-million-ways__section--talents"
        key={section.strapi_component}>
        <div className="a-million-ways__section-wrapper">
          <header className="a-million-ways__talents-header">
            {section.subheading &&
              <span className="a-million-ways__talents-subheading">
                {section.subheading}
              </span>
            }

            {section.heading &&
              <h2 className="a-million-ways__heading">
                {section.heading}
              </h2>
            }

            {section.description &&
              <div
                className="a-million-ways__paragraph a-million-ways__paragraph--small a-million-ways__talents-description"
                dangerouslySetInnerHTML={{ __html: section.description.replace(/\n/g, '<br />') }} />
            }
          </header>

          {!!section.artists.length &&
            <div className="a-million-ways__project-artists">
              {section.artists.map((artist, index) => {
                return (
                  <div
                    className="a-million-ways__project-artist"
                    key={index}>
                    {artist.image &&
                      <img
                        className="a-million-ways__artist-image"
                        src={artist.image.formats.small.url}
                        loading="lazy"
                        alt={artist.name} />
                    }
                    <h3 className="a-million-ways__project-artist-name">
                      <img
                        className="a-million-ways__artist-name-image"
                        src={artist.name_image.url}
                        alt={artist.name} />
                    </h3>
                  </div>
                )
              })}
            </div>
          }

          {section.cta &&
            <div className="a-million-ways__button-group">
              <a
                href={section.cta.link_url}
                className="nts-app a-million-ways__button">
                {section.cta.label}
              </a>
            </div>
          }
        </div>
      </section>
    )
  }

  function renderAMillionWaysCtaBlock(section) {
    return (
      <AMillionWaysCtaBlock
        section={section}
        key={section.strapi_component + section.id} />
    )
  }

  function renderBgImageWithCtaBlock(section) {
    return (
      <section
        className="a-million-ways__section a-million-ways__bg-image-with-cta"
        key={section.strapi_component}>
        {section.bg_image_small &&
          <div
            className="a-million-ways__bg-image a-million-ways__bg-image--small"
            style={{ backgroundImage: `url(${section.bg_image_small.formats.medium.url})` }} />
        }
        {section.bg_image &&
          <div
            className="a-million-ways__bg-image a-million-ways__bg-image--large"
            style={{ backgroundImage: `url(${section.bg_image.formats.large.url})` }} />
        }
        <div className="a-million-ways__bg-image-with-cta-contents">
          <div className="a-million-ways__bg-image-with-cta-contents-wrapper">
            {section.heading &&
              <h2 className="a-million-ways__heading">
                {section.heading}
              </h2>
            }

            {section.body &&
              <div
                className="a-million-ways__paragraph a-million-ways__paragraph--small"
                dangerouslySetInnerHTML={{ __html: section.body.replace(/\n/g, '<br />') }} />
            }

            {section.cta &&
              <div className="a-million-ways__button-group">
                {renderCustomLink(section.cta.link_url, section.cta.label)}
              </div>
            }
          </div>
        </div>
      </section>
    )
  }

  return (
    <>
      <StaticQuery
        query={query}
        render={data => {
          const sections = data.strapiAMillionWays.sections
          if (!sections.length) return null

          return (
            <Layout>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
              <link
                href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@400;700&display=swap"
                rel="stylesheet" />

              <style>{AMillionWaysStyles}</style>

              <main className="a-million-ways">
                {sections.map((section) => {
                  switch(section.strapi_component) {
                  case 'a-million-ways.a-million-ways-hero':
                    return renderHero(section)
                  case 'a-million-ways.a-million-ways-about':
                    return renderAbout(section)
                  case 'a-million-ways.artists':
                    return renderTalents(section)
                  case 'a-million-ways.a-million-ways-cta-block':
                    return renderAMillionWaysCtaBlock(section)
                  case 'components.bg-image-with-cta-block':
                    return renderBgImageWithCtaBlock(section)
                  default:
                    return null
                  }
                })}
              </main>
            </Layout>
          )
        }}
      />
    </>
  )
}

export default AMillionWays
